@import '../../../variables';

.Topbar {
  @include boxShadow(light);
  background: $white;
  position: sticky;
  top: 0;
  user-select: none;
  z-index: 99;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.Topbar-inner {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 ($spacer * 2);
  position: relative;
  min-height: $spacer * 4;
}

.Topbar-content {
  display: flex;
  width: 100%;
}

.Topbar-search {
  align-self: center;
  background: $white;
  margin-top: 1px;
  padding: ($spacer / 1.5);
  padding-left: 0;
  position: relative;
  width: $spacer * 3;
  z-index: 1;

  .Topbar.is-active & {
    width: rem(380px);
  }

  .btn {
    margin: 0;
  }

  .search-advanced {
    .btn {
      margin: 2px 0;
    }
  }

  .input-group-sm > .input-group-prepend > .input-group-text {
    background: none;
    border-color: transparent;
    cursor: pointer;
    min-height: 39px;
    padding-bottom: 0.12rem;
    padding-top: 0.18rem;

    > .Icon {
      font-size: $Icon-fontSize-s;
    }

    .Topbar.is-active & {
      width: rem(30px);
      border-color: $border-color;
    }

    .dropdown {
      display: inline-block;
      margin-right: 3px;
      margin-top: 3px;
    }
  }
}

.Topbar-tabs {
  left: $spacer * 2;
  position: absolute;
  top: 0;
  width: 80%;
  z-index: 0;

  .Topbar.has-search & {
    left: $spacer * 5 !important;
  }

  .Topbar.is-active & {
    left: rem(380px) + ($spacer * 2);
  }

  .nav-tabs {
    border: 0;

    .nav-item {
      border: 0;
      background: transparent;
      border-bottom: 5px solid transparent;
      font-size: $font-size-sm;
      margin-top: 7px;
      outline: 0;
      padding: ($spacer * 1.33) 0 calc(#{$spacer * 1.25} - 3px) 0;
      margin: 0 15px;

      &.active,
      &:hover {
        border-color: $primary;
      }
    }
  }
}

.Topbar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  position: relative;
  z-index: 0;

  > .input-group-sm {
    cursor: pointer;
    width: auto;

    > .input-group-append {
      > .input-group-text {
        background: none;
        border: 0;

        > .Icon {
          font-size: $Icon-fontSize-s;
        }
      }
    }
  }

  .topbar-map-dropdown {
    background-color: white;
    border: black;
    margin-bottom: 0;
  }
  .las.la-map {
    color: #495057;
    font-size: 24px;
  }
}
