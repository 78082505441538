// =======================================
// Various mixins
// =======================================

@mixin boxShadow($style: light) {
  @if $style == light {
    box-shadow: 0 4px 6px 0 rgba(95, 95, 95, 0.03),
                0 1px 3px 0 rgba(0, 0, 0, 0.04),
                0 -1px 1px 0 rgba(0, 0, 0, 0.05);
  } @else if $style == lightRight {
    box-shadow: 4px 0 6px 0 rgba(95, 95, 95, 0.03),
                1px 0 3px 0 rgba(0, 0, 0, 0.04),
                0 -1px 1px 0 rgba(0, 0, 0, 0.05);
  } @else if $style == strong {
    box-shadow: 0 5px 6px 0 rgba(95, 95, 95, 0.12),
                0 1px 3px 0 rgba(0, 0, 0, 0.13),
                0 -1px 1px 0 rgba(0, 0, 0, 0.1);
  } @else if $style == stronger {
    box-shadow: 0 5px 6px 0 rgba(95, 95, 95, 0.2),
                0 1px 3px 0 rgba(0, 0, 0, 0.21),
                0 -1px 1px 0 rgba(0, 0, 0, 0.18);
  } @else {
    box-shadow: 0 5px 6px 0 rgba(95, 95, 95, 0.07),
                0 1px 3px 0 rgba(0, 0, 0, 0.08),
                0 -1px 1px 0 rgba(0, 0, 0, 0.05);
  }
}

@mixin textLink($color: $body-color) {
  color: $color;
  text-decoration: underline;
  text-decoration-color: lighten($color, 25);

  &:hover {
    color: $color;
  }
}

@mixin textLinkPlain($color: $body-color) {
  color: $color;

  &:hover {
    color: $color;
    text-decoration: unrderline;
  }
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function rem($pixels) {
  @return strip-unit($pixels) / strip-unit(16px) * 1rem;
}
