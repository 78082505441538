.lightbox-trigger {
  img {
    cursor: pointer;
  }
}

.Lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000000e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Lightbox-inner {
    max-height: 90%;
    max-width: 90%;

    .Lightbox-body {
      position: relative;
      padding: 40px;
      max-width: 90%;
      max-height: 80%;
      overflow: hidden;
      margin: auto;

      button {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background: none;
        color: white;
        font-weight: bold;
        border: 0;
        font-size: 30px;
        line-height: normal;
        cursor: pointer;
      }
      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .Lightbox-footer {
      margin-top: 15px;

      .Lightbox-thumbnails {
        border-top: 1px solid #ffffff75;
        text-align: center;

        img {
          width: 84px;
          height: 100px;
          padding: 16px 8px;
          cursor: pointer;
          opacity: 0.5;
          &.active {
            opacity: 0.8;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
