@import '../../../variables';

.tags-input {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid rgb(214, 216, 218);
  input {
      flex: 1;
      border: none;
      &:focus {
        border-color: none !important;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 8px;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-text;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  margin: 0 8px 8px 0;
  border: 1px solid $primary;
  cursor: pointer;
  &.deactivated { border-color: #aaa; color: #aaa; }
  &.newtag { border-color: #090; color: #090; }
  .tag-title {
      margin-top: 3px;
  }
  .tag-close-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      margin-left: 8px;
      color: $primary-text;
      background: #fff;
      cursor: pointer;
  }
}
