.modal-project-statuses {
  .trash.btn.btn-danger {
    max-height: 46.6667px;

    svg.Icon {
      display: inline-block;
      fill: currentColor;
      font-size: 27px;
      margin: 0;
      height: 1em;
      stroke-width: 0;
      stroke: currentColor;
      width: 1em;
      padding: 0;
      color: #fff !important;
    }
  }
  .status-label {
    font-weight: 700;
    margin-top: 0.5rem;
  }

  svg.Icon {
    &.icon.active {
      color: #008000;
    }
    &.icon.inactive {
      color: #212529;
    }
  }
}