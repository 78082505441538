@import '../variables';

:root {
  color: $body-color;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
}

a, a:hover {
  color: $body-color;
  text-decoration: none;
}

table {
  margin-bottom: $spacer;
}
