@import "../../../variables";

.avainia-list {
  padding: 0 15px;
  list-style: none;
  font-size: 13px;
  font-weight: bold;

  > div {
    margin-bottom: 15px;
  }

  .avainia-list-title {
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
    padding-bottom: 4px;
    margin-bottom: 15px;

    &:before {
      content: "\25C6";
      color: $primary;
      margin-right: 6px;
      font-size: 80%;
      display: inline-block;
      position: relative;
      top: -2px;
    }
  }
}
