.edit-materialform-modal {
  min-height: 450px;

  .material-form-selections {
    margin-bottom: 0px;
  }

  .material-options {
    font-weight: 600;
    font-size: 14px;

    /* To give more room for 'default' text */
    .col-sm-1:first-child {
      padding: 0 0 0 10px;
    }
  }

  .typicalBox {
    margin-bottom: 1rem;
  }

  button.btn.btn-link {
    padding: 0.5rem;
    margin: 0 12px 0 0;
    color: black;
  }

  .collapsed .chevron .Icon {
    transform: rotate(0deg);
    transition: transform 0.2s;
  }

  .expanded .chevron .Icon {
    transform: rotate(90deg);
    transition: transform 0.2s;
  }

  .chevron.row {
    line-height: 2;
  }

  .additional-products {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /* TODO: consider making this a general styling  */
  .custom-modal {
    min-width: 50vw;

    @media only screen and (min-width: 576px) {
      max-width: 100vw;
    }
    @media only screen and (min-width: 992px) {
      max-width: 80vw;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1140px;
    }
    @media screen and (min-width: 1400px) {
      max-width: 1370px;
    }
    @media screen and (min-width: 1600px) {
      max-width: 1570px;
    }
    @media screen and (min-width: 1900px) {
      max-width: 1870px;
    }
  }
}
