.search-results {
  .result-row {
    td {
      border-bottom: 1px solid;
      vertical-align: top;
      padding: 10px;

      .image-thumbnail-mini {
        height: 200px;
        border: 0;
      }
    }

    &:hover td {
      background-color: #cfdfff;
    }

    &:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }
}

.search-modal-body {
  // width: 845px;
  // margin: 0 auto;
}