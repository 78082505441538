.additional-choices-text-area {
  height: 150px !important;
  padding-right: 15% !important;
}

.additional-choices-price {
  margin-top: 105px !important;
}

.additional-row-title {
  display: inline-block;
  width: 250px;
}
.additional-row-description,
.additional-quote-info {
  display: inline-block;
  width: 450px;
}
.additional-row-price {
  display: inline-block;
  width: 120px;
}
.additional-row-labor-price {
  display: inline-block;
  width: 150px;
}
.additional-row-button {
  display: inline-block;
  margin-bottom: 0;
  width: 100px;
}

.additional-rows-heading {
  * {
    font-weight: 600;
  }
}

.product-select-container {
  .dropdown-menu {
    position: initial !important;
    transform: none !important;
  }
}

/* TODO: consider making this a general styling  */
.custom-modal {
  min-width: 50vw;

  @media only screen and (min-width: 576px) {
    max-width: 100vw;
  }
  @media only screen and (min-width: 992px) {
    max-width: 80vw;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (min-width: 1400px) {
    max-width: 1370px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1570px;
  }
  @media screen and (min-width: 1900px) {
    max-width: 1870px;
  }
}

.product-select-btn {
  margin-bottom: 1rem;
}
