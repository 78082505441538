/* =======================================
 * App
 * =======================================
 *
 * Wraps the entire application.
 */

@import '../variables';
@import '../mixins';

.App {
  background: $appBgColor;

  > .Loading {
    background: $appBgColor;
    padding-left: $Sidebar-width;
  }

  #mobilemenu {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}

  .App-main {
    color: $dark;
    min-height: 100vh;
    min-height: calc(100vh - 63px);
    margin-left: $Sidebar-width;
    transition: $Sidebar-speed margin;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }

    .App.is-sidebarActive & {
      margin-left: $spacer * 2;
    }
  }

    .App-container {
      padding: $spacer * 2;

      h1 {
        font-size: $h2-font-size;
        margin-bottom: $spacer * 2;
      }
    }
