@import 'variables';
@import 'mixins';
@import 'bootstrap';
@import 'stylesheets/base';
@import 'stylesheets/helpers';
@import 'stylesheets/vendors/bootstrap';
@import 'stylesheets/vendors/materialforms';
@import 'stylesheets/vendors/react-datepicker';

// TODO: Move

.search-modal-body {
  max-width: 100%;
  max-height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  background-color: #525659;
  overflow: auto;
}

.search-result-modal {
  .modal-dialog {
    margin: 0;
    max-width: 100vw;

    .modal-content {
      max-height: 100vh;
      width: 100vw;
    }
  }

  mark {
    background-color: rgba(255, 255, 100, 0.5);
    color: transparent;
    padding: 0;
    margin: 0;
  }
}

// TODO: Move
.searchResult {
  .result-thumb {
    height: 100px;
  }

  td {
    padding: 3px;
  }

  &:hover {
    background-color: #fff;
  }
}
