@import '../../../variables';

.AvainiaPanel {
  display: flex;
  flex-wrap: wrap;
  margin: 0 ($spacer * -1);
}

  .AvainiaPanel-panel {
    margin: $spacer $spacer ($spacer * 2) $spacer;
    flex: 1 0 33.3%;
  }

  .AvainiaPanel-panel--double {
    flex: 2 0 66.666%;
  }

  .AvainiaPanel-panel--header {
    flex: 1 100%;

    .AvainiaPanel-content {
      box-shadow: none;
      background: transparent;
      padding: 0;
    }
  }

    .AvainiaPanel-content {
      @include boxShadow();
      background-color: $white;
      border-radius: $border-radius;
      height: 100%;
      padding: $spacer ($spacer * 2);
      width: 100%;
    }

      .AvainiaPanel-icon {
        @include boxShadow(strong);
        align-items: center;
        background: $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        top: -40px;
        position: relative;
        width: 64px;
        height: 64px;
      }

      .AvainiaPanel-heading {
        font-size: $h4-font-size;
        hyphens: auto;
        position: relative;
        text-align: center;
        top: $spacer * -1;
      }

      .AvainiaPanel-section-list {
        padding: 0 15px;
        list-style: none;
        font-size: 13px;
        font-weight: bold;

        > li {
          margin-bottom: 15px;
        }
      }

      .AvainiaPanel-section-title {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #e2e2e3;
        padding-bottom: 4px;
        margin-bottom: 15px;

        &:before {
          content: "\25C6";
          color: $primary;
          margin-right: 6px;
          font-size: 80%;
          display: inline-block;
          position: relative;
          top: -2px;
        }
      }

.AvainiaPanel--fit {
  margin: 10px;
}
