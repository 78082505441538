@import '../../../variables';

.stairwell {
  margin-top: 6rem;

  // This selector affects the first element instead of the second one which is a mystery
  &:nth-of-type(2) {
    margin-top: 0;
  }

  .table-headers {
    th {
      padding: 0.5rem;
    }

    &--sub {
      font-size: $fontSizeSm;
    }
  }

  .table-body {
    font-size: $fontSizeSm;
  }

  .table-striped {
    tbody tr {
      height: 1px; // this is ignored but it's needed for button height

      td {
        height: inherit;

        button {
          height: 100%;
        }
      }

      &:nth-of-type(odd) {
        background-color: white;
      }
      &:nth-of-type(even) {
        background-color: #f3f3f3; // or #f2f6fe like everywhere else
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .status-badge {
    display: flex;
    margin-right: 2rem;
    font-size: $fontSizeXs;

    div {
      border-radius: 50%;
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
      align-self: center;
      margin-bottom: 4px;
    }

    p {
      margin: 0 0 0 0.75rem;
      text-align: center;
      align-self: center;
    }
  }
}
