@import '../../../variables';

.Login {
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100vh;
  min-height: 540px;
  padding: $spacer * 1.5;
  padding-bottom: $spacer * 5;
  position: relative;
  width: 100%;

  &:after {
    background: linear-gradient(transparent 30%, rgba(#000, .4));
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

  .Login-card {
    @include boxShadow();
    background: #fff;
    border: 0;
    font-size: $fontSizeXs;
    margin: auto;
    max-width: $spacer * 25;
    position: relative;
    width: 100%;
    z-index: 2;

    .card-body {
      text-align: center;
    }

    .card-footer {
      font-size: $fontSizeXs;
      padding: ($card-spacer-y * 1.5) $card-spacer-x;
      text-align: left;

      .dropup {
        text-align: right;

        > .btn {
          font-size: $fontSizeXs;
          margin: 0;
        }
      }
    }
  }

    .Login-logo {
      margin-bottom: $spacer;
      padding: 0.6rem;

      img {
        max-width: 200px;
        width: 100%;
      }
    }

    .Login-email {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: -1px;
    }

    .Login-password {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-bottom: $spacer;
    }

    .Login-terms,
    .Login-forgotPassword {
      a {
        @include textLink();
      }
    }

    .Login-terms {
      @include text-truncate();
    }

    .Login-forgotPassword {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

  .Login-footer {
    bottom: 0;
    display: flex;
    left: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;

    img {
      display: block;
      height: 100%;
      margin-bottom: $spacer * 2;
      width: 120px;
    }
  }
