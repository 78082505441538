#root {
  /* The styles below were migrated from Materialforms.scss and need to be refactored at some point */
  .group {
    margin: 10px 0;
    padding: 10px;
    border: 2px solid #eee;
    border-radius: 3px;

    .select {
      margin: 10px 0;
      padding: 10px;
      border: 2px solid #eee;
      border-radius: 3px;

      .inlineradio {
        margin: 7px 10px;
      }

      .optionBox {
        margin: 10px 0;
        padding: 10px;
        border: 2px solid #eee;
        border-radius: 3px;
      }
    }
  }

  .selectionform {
    .select h3 { text-align: center; }

    .option {
      margin: 10px;
      padding: 10px;
      background-color:#eee;
      border-top: 2px solid #eee;

      &.selected { background-color: #dcf0dc; }
    }

    .vcentre { margin: auto 0; }

    .radiobox { height: 20px; }

    .forceRight {
      text-align: right;
    }
  }
}