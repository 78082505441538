@import "../../../variables.scss";

/**
 * @extends react-bootstrap-table-next in node_modules/
 */
.AvainiaTable {
  @include boxShadow();
  background-color: $white;
  border: none;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  overflow: hidden;
  padding: 3px;
  margin-bottom: $spacer * 2;

  th, td {
    border: 0;
    padding: 15px;
  }

  thead th {
    border-bottom: 1px solid #ececec;
    font-weight: 500;
  }

  td {
    &:first-of-type { font-weight: bold; }
  }

  .Icon {
    font-size: $Icon-fontSize-s;
  }

  &.table-striped {
    tbody tr {
      &:nth-of-type(odd) {
        background-color: white;
      }
      &:nth-of-type(even) {
        background-color: #f2f6fe;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.075)
      }
    }
  }
}
