/**
 * Buttons
 */
.btn {
  margin-bottom: $spacer * 2;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

/**
 * Add custom rounded highlight button style
 */
.btn-highlight {
  @include boxShadow(strong);
  align-items: center;
  background: $white;
  border-radius: 60px;
  display: flex;
  height: $spacer * 2.5;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: all $transitionSpeed;
  width: $spacer * 2.5;

  > .Icon {
    font-size: $Icon-fontSize-s;
  }

  > .badge {
    border-radius: 60px;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  &:focus,
  &:hover {
    @include boxShadow(stronger);
    transform: scale(1.15);
  }

  &.btn-sm {
    height: $spacer * 1.5;
    width: $spacer * 1.5;

    > .Icon {
      font-size: $Icon-fontSize-xs;
    }
  }
}

/**
 * Customized button for Materialform quotes panel
 */
.btn-light {
  padding: 0.5rem;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  display: flex;
  font-size: $fontSizeXs;
  line-height: 0.8;
  margin: 0;
  text-align: left; // Looks funny but center looks funnier
  svg {
    margin-right: $spacer / 3;
  }

  &--input {
    padding: 0;
  }

  &--dropdown {
    .dropdown-toggle::after {
      display: none;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: transparent;
  }
}

/**
 * Modals
 */
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-open {
  overflow-y: scroll !important;
}

.modal {
  overflow-y: hidden !important;
}

/**
 * Forms
 */
.form-control {
  margin-bottom: $spacer;
}

/**
 * Lists
 */
.listing {
  margin-bottom: $spacer * 2;

  .list-group-item {
    padding: $spacer ($spacer * 1.5);

    .Icon {
      cursor: pointer;
      font-size: $Icon-fontSize-s;
      float: right;
      margin-left: $spacer / 4;

      &:last-child {
        margin-right: rem(-2px);
      }
    }

    .badge {
      margin-left: ($spacer / 2);
    }
  }

  .listing {
    margin: $spacer 0 0 0;

    .list-group-item {
      padding: ($spacer * 0.75) ($spacer * 1.5);
    }
  }
}

/**
 * Toasts
 */
.toast {
  .Icon {
    font-size: $Icon-fontSize-s;
  }
}
