.modal-content > .modal-body > .form-group > label.label-select-file {
  text-decoration: none;
  background-color: #eeeeee;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
}

.notification-info {
  background-color: #f8f3d2;
  border-color: lightgrey;
  margin: 16px 2px;
  padding: 20px;
  box-shadow: 1px 1px 4px 0px #a0a0a0;

  .notification-info-heading {
    font-weight: bold;
    font-size: 17px;
  }

  .notification-excerpt {
    font-size: 17px;
  }

  .notification-read-more {
    color: #6c757d;
    text-decoration: underline;
    -webkit-text-decoration-color: #afb5ba;
    text-decoration-color: #afb5ba;
    font-size: 0.85rem;
    cursor: pointer;
    padding: 5px;
  }
}

button.notification-download-link.btn.btn-link {
  color: #0303af;
  margin-bottom: 0px;
  padding: 0;
}

.notification-button {
  margin-left: 10px;
}
