.modal-default-folders {

  .permissions-for-company.defaultfolders {
    border: none;
    padding: 0;

    .informational-grid {
    width: 100%;

      thead {
        border: 1px solid #000 !important;
      }

      tbody {
        .role-assigned {
          color: #008000;
        }

        .role-not-assigned {
          color: #848484;
        }
      }
    }

    td {
      border: none;
    }

    tr {
      line-height: 32px;
    }
  }

  .trash.btn.btn-danger {
    max-height: 46.6667px;

    svg.Icon {
      display: inline-block;
      fill: currentColor;
      font-size: 27px;
      margin: 0;
      height: 1em;
      stroke-width: 0;
      stroke: currentColor;
      width: 1em;
      padding: 0;
      color: #fff !important;
    }
  }

  .modal-default-folders-childbox {
    padding: 10px;
    margin: 15px;
    border: '1px solid #aaa';
    border-radius: 3;
    background: '#fafafa';
  }
}
