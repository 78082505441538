@import '../../../variables';

.Loading {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
}

  .Loading-text {
    margin: 0 $spacer 0 0;
  }

  .Loading-icon {
    width: 30px;
    height: 30px;
    position: relative;
  }

  .Loading-inner {
    animation: bounce 2.0s infinite ease-in-out;
    background-color: $primary;
    border-radius: 50%;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;

    &:last-child {
      animation-delay: -1.0s;
    }
  }

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
}


/* ======
 * Modifiers
 * ====== */

.Loading--inline {
  background: transparent;
  height: auto;
  padding: $spacer * 2;
  position: relative;
  width: 100%;
}
