// =======================================
// Variables
// =======================================

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'mixins';


// =========
// Bootstrap Variables
// =========

// Global
$enable-rounded: true;
$border-radius: 3px;
$border-color: #ced4da; // $gray-400

// Colors
$primary: #f3db3d;
// $primary: #cb073a;
$primary-text: darken($primary, 18);

// Buttons + Forms
$input-btn-padding-y: .66rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: .5rem;
$input-btn-padding-x-sm: .5rem;

// Card
$card-spacer-y: 0.66rem;
$card-spacer-x: 1.5rem;

// Import other bootstrap variables
@import '~bootstrap/scss/variables';

// Headings
$headings-margin-bottom: $spacer;


// =========
// Custom Variables
// =========

$fontSizeSm: $font-size-base * .9;
$fontSizeXs: $font-size-base * .8;
$fontSizeXxs: $font-size-base * .7;

$appBgColor: #e7ebf2;
$appBgColorLight: lighten($appBgColor, 3);

$transitionSpeed: 0.2s;


// =========
// Component Variables
// =========

$Sidebar-speed: $transitionSpeed;
$Sidebar-width: rem(225px);

$Icon-fontSize: 32px;
$Icon-fontSize-s: 24px;
$Icon-fontSize-xs: 16px;
