.modal-document-body {
  max-width: 100%;
  width: 100%;
  overflow-y: auto;
  max-height: unset;
}

.modal-document-footer {
  position: absolute;
  bottom: 1px;
  background-color: white;
  width: 95%;
}

.modal-document-buttons {
  height: 95px;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

#ModalViewDocument .toast-body .document-thumbnail {
  height: 50%;
}

.replace-file.label.label-select-files {
  margin: 20px 0;
}

.d-flex.modal-buttons.btn-group {
  flex-wrap: wrap;
}

.d-flex.modal-buttons.btn-group button.btn.btn-primary {
  margin-bottom: 0.2rem;
}

.d-flex.modal-buttons.small.btn-group > button.btn.btn-primary {
  width: 25%;
}
