@import "../../../variables";

.Footer {
  align-items: center;
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  margin-left: $Sidebar-width;
  padding: $spacer ($spacer * 2);
  transition: $Sidebar-speed margin;

  .App.is-sidebarActive & {
    margin-left: $spacer * 2;
  }
}

  .Footer-logo {
    height: 30px;
    margin: 0 0 0 $spacer;
  }

  .Footer-text {
    font-size: $fontSizeXs;
    margin: 0;
  }
