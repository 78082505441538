@import '../../../variables';

.Icon {
  display: inline-block;
  fill: currentColor;
  font-size: $Icon-fontSize;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  width: 1em;

  // The icons from from https://heroicons.com/
  &--hero {
    display: inline-block;
    font-size: $Icon-fontSize;
    height: unset;
    stroke-width: 1px;
    stroke: currentColor;
    width: 0.8em;
  }
}
