@import "../../../../variables";

.DocumentReportDate {

  .InnerTableContainer {
    padding: 0;
  }

  tbody:nth-of-type(even) {
    background-color: #f2f6fe;
  }

  tr.hidden {
    display: none;
  }

  tbody + tbody {
    border-top: 0;
  }

  .document {
    color: #848484;
  }

  table .btn {
    padding: 4px 10px;
    font-size: 13px;
    margin: 0;
  }

  tr.selected {
    background-color: lightgreen;
  }

  .caret-asc {
    &::before {
      opacity: 1;
      content: "\2193";
    }
    &::after {
      opacity: .4;
      content: "\2191";
    }
  }

  .caret-desc {
    &::before {
      opacity: .4;
      content: "\2193";
    }
    &::after {
      opacity: 1;
      content: "\2191";
    }
  }

}
