@import "../../../../variables";

  .DocumentTable {
    .AvainiaTable {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
    }
    .DocumentTable-bottomPadding {
      width: 100%;
      height: 70px;
      background: white;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      @include boxShadow();
      margin-bottom: $spacer * 2;
    }

    .badge {
      &.visibilityBadge {
      margin-right: 10px;
      color: #fff;

        &.badge-global {
          background-color: #28a745;
        }
        &.badge-project {
          background-color: #007bff;
        }
        &.badge-owner {
          background-color: #17a2b8;
        } // teal
        &.badge-ownertenant {
          background-color: #ffc107;
        }
      }
    }
  }

  .DocumentTable-topbar {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    height: 45px;

    .DocumentTable-controls {
      display: flex;
      .DocumentTable-controls-inner {
        display: flex;
        background: white;
        border: 1px solid #ced4da;
        border-radius: 4px;
        align-items: center;
        .is-active {
          background: $primary;
        }
        .reports, .settings {
          position: relative;
          .settings-dropdown {
            z-index: 10;
            position: absolute;
            left: 0;
            background: white;
            border: 1px solid #ced4da;
            border-radius: 4px;
            top: 100%;
            margin-top: 6px;
            padding: 12px;
            span {
              margin: 0;
              display: block;
              padding: 4px 8px;
              white-space: nowrap;
            }
            label {
              padding-left: 6px;
              margin: 0;
            }
          }
        }
      }
    }
  }

    .DocumentTable-controls-modetoggle {
      cursor: pointer;
      margin-left: $spacer;
      user-select: none;
      border-right: 1px solid #ced4da;
      padding: 0 8px;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      .Icon {
        margin-top: -2px;
      }
      &:last-child {
        border: 0;
      }
    }
